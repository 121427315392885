import Vue from 'vue'
import VueI18n from 'vue-i18n'
import English from './i18n/en.json'
import Japanese from './i18n/jp.json'
//elementUI set up
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
//import 'element-ui/lib/theme-chalk/index.css';
import {gsap} from 'gsap';
//
import App from './App.vue'
//import './registerServiceWorker'
import router from './router'
import store from './store'
import VueAnalytics from 'vue-analytics';
//setting axios
const axios = require('axios').default;

/*const messages = {
  en: {
    message: {
      hello: 'hello world'
    }
  },
  ja: {
    message: {
      hello: 'こんにちは、世界'
    }
  }
}*/
const messages = {
  English,
  Japanese
}
//elementUI use
Vue.use(ElementUI);
Vue.use(VueI18n)

Vue.config.productionTip = false

Vue.use(VueAnalytics, {
	    id: 'UA-138581201-1',
	    router
})
const i18n = new VueI18n({
  locale: store.state.lang, // set locale
  messages // set locale messages
})
//console.log('alilalalala',mes)
/*if ('serviceWorker' in navigator) {
	  navigator.serviceWorker.getRegistrations().then(registrations => {
		      for (let registration of registrations) {
			            registration.unregister().then(() => {
					            console.log('Service worker unregistered.');
					          }).catch(error => {
							          console.error('Error unregistering service worker:', error);
							        });
			          }
		    });
}*/

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
