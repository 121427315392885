import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang:'English',
    xsDetail:false,
    first:false,
    drawer:false,
    order: 0,
    loading:'',
    bar:'',
    barWidth:'',
    clock:'',
    timeStop:false,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
