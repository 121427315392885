<template>
  <div>
    <div id="myProgress" :style="'margin-top:'+this.$store.state.loading+'px;'+'width:'+this.$store.state.bar+'px;'" @mouseover="getInfo">
      <div id="myBar" class="loadBar" :style="'width:'+this.$store.state.barWidth+'%;'">
        <div style="text-align:center;">
          <p style="font-size:18px;border: solid 1px rgba(0, 0, 0, 0.5);line-height: 58px;" :style="'width:'+this.$store.state.bar+'px;'">Know more</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {gsap} from 'gsap';
export default {
  name: 'Loading',
  components: {
  },
  data() {
    return {

    };
  },
  methods: {
    getInfo(){
      console.log('')
    },
  },
  mounted(){

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#myProgress {
  width: 100%;
  background-color: transparent;
}
#myBar {
  height: 60px;
  background-color:rgba(216,216,216,0.5);
}
</style>
