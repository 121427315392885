<template>
  <div class="home" @wheel.prevent="toAbout">
    <el-col>
      <div>
      <el-carousel :interval="5000" :height="homeHeight+'px'" style="">
        <Header/>
        <img id="whitelogo" class="hidden-sm-and-down" src="../assets/logo.svg" alt="">
        <el-carousel-item v-for="item in 3" :key="item" style="z-index:-1;">
          <el-col :xs="0" :sm="24" >
            <img style="width:100%;" :src="require('../assets/home'+item+'.webp')"></img>
          </el-col>
          <el-col :xs="24" :sm="0" >
            <img :style="'height:'+homeHeight+'px;margin-left:-360px;'" :src="require('../assets/home'+item+'.webp')"></img>
          </el-col>
        </el-carousel-item>
        <el-row style="margin-top:0px;" >
          <el-col :xs="0" :sm="18" >&nbsp;</el-col>
          <el-col :xs="0" :sm="6" style="float:right;" :style="'margin-top:'+infoHeight+'px;'">
            <div id="notification" style="width:100%">
              <h2 style="margin-left:20px;">NEWS</h2>
              <p style="margin-left:20px;">New Product Release: Matcha Nama Chocolate</p>
            </div>
          </el-col>
          <el-col :xs="24" :sm="0" style="float:right;" :style="'margin-top:'+mobileHeight+'px;'">
            <div id="notification" style="width:100%">
              <h2 style="margin-left:20px;">NEWS</h2>
              <p style="margin-left:20px;">New Product Release: Matcha Nama Chocolate</p>
            </div>
          </el-col>
        </el-row>
      </el-carousel>
      </div>
    </el-col>
  <Drawer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Drawer from '@/components/Drawer.vue';
import Header from '@/components/Header.vue';
import {gsap} from 'gsap';
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
gsap.registerPlugin(DrawSVGPlugin, MorphSVGPlugin);
export default {
  name: 'home',
  components: {
    Drawer,
    Header,
  },
  data(){
    return {
      show:true,
      homeHeight:'',
      infoHeight:'',
      mobileHeight:''
    };
  },
  methods: {
    toAbout(evt){
      var timeoutID = window.setTimeout(( () => this.$router.push('/about')), 500);
    }
  },
  mounted(){
    this.homeHeight=Number(window.innerHeight).toString()
    this.infoHeight=Number(window.innerHeight-470).toString()
    this.mobileHeight=Number(window.innerHeight-115).toString()
    gsap.set('.home',{opacity:0})
    gsap.set('#whitelogo',{y:225,scale:0.95})
    var ti = gsap.timeline({repeat: 0, repeatDelay: 1});
    ti.to('.home',1,{opacity:1})

  }
};
</script>
<style scoped>
#notification{
  height:116px;
  width:475px;
  text-align: left;
  float:right;
  color:white;
  background-color: rgba(0, 0, 0, .6)
}
</style>
