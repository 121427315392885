import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Home0 from '../views/Home0.vue'
import Style from '../views/Style.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home0',
    component: Home0
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
      path: '/history',
      name: 'history',
      component: () => import(/* webpackChunkName: "about" */ '../views/History.vue'),
  },
  {
      path: '/product',
      name: 'product',
      component: () => import(/* webpackChunkName: "about" */ '../views/Product.vue'),
  },
  {
      path: '/design',
      name: 'design',
      component: () => import(/* webpackChunkName: "about" */ '../views/Design.vue'),
  },
  {
      path: '/style',
      name: 'style',
      component: Style,
      //() => import(/* webpackChunkName: "about" */ '../views/Style.vue'),

  },
  {
      path: '/shop',
      name: 'shoptitle',
      component: () => import(/* webpackChunkName: "about" */ '../views/Shop.vue'),
  },
  {
      path: '/shop/:title',
      name: 'shop',
      component: () => import(/* webpackChunkName: "about" */ '../views/Shop.vue'),
  },
  {
      path: '/franchise',
      name: 'franchise',
      component: () => import(/* webpackChunkName: "about" */ '../views/Franchise.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
