<template>
  <div>
    <el-row>
      <el-col :xs="0" :sm="24">
        <span style="cursor:pointer">
          <svg version="1.1" id="圖層_1" width="40" style="position:fixed;right:24px;top:18px;z-index:9999;enable-background:new 0 0 36 31;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 36 31" xml:space="preserve" @click="drawer">
            <g id="bridge">
                <path class="st0 line0" d="M36,5H0V0h36V5z" :fill="color"/>
                <path class="st0 br0" d="M16,9H0v5h16V9z" :fill="color"/>
                <path class="st0 br0" d="M36,9H20v5h16V9z" :fill="color"/>
                <path class="st0 br1" d="M16,17H0v5h16V17z" :fill="color"/>
                <path class="st0 br1" d="M36,17H20v5h16V17z" :fill="color"/>
                <path class="st0 line1" d="M36,26H0v5h36V26z" :fill="color"/>
            </g>
            <g id="xx">
                <path class="st1 x0" d="M28.8,32L0,3.2L3.2,0L32,28.8L28.8,32z"/>
                <path class="st1 x1" d="M32,3.2L3.2,32L0,28.8L28.8,0L32,3.2z"/>
            </g>
          </svg>
        </span>
      </el-col>
      <el-col :xs="24" :sm="0">
        <svg version="1.1" id="圖層_1" width="30" style="position:fixed;right:24px;top:22px;z-index:9999;enable-background:new 0 0 36 31;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 36 31" xml:space="preserve" @click="drawer">
          <g id="bridge">
              <path class="st0 line0" d="M36,5H0V0h36V5z" :fill="color"/>
              <path class="st0 br0" d="M16,9H0v5h16V9z" :fill="color"/>
              <path class="st0 br0" d="M36,9H20v5h16V9z" :fill="color"/>
              <path class="st0 br1" d="M16,17H0v5h16V17z" :fill="color"/>
              <path class="st0 br1" d="M36,17H20v5h16V17z" :fill="color"/>
              <path class="st0 line1" d="M36,26H0v5h36V26z" :fill="color"/>
          </g>
          <g id="xx">
              <path class="st1 x0" d="M28.8,32L0,3.2L3.2,0L32,28.8L28.8,32z"/>
              <path class="st1 x1" d="M32,3.2L3.2,32L0,28.8L28.8,0L32,3.2z"/>
          </g>
        </svg>
      </el-col>
    </el-row>
    <Drawer/>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

gsap.registerPlugin(MorphSVGPlugin);
import Drawer from '@/components/Drawer.vue';
export default {
  name: 'Header',
  components: {
    Drawer,
  },
  data() {
    return {
      color:'#10312A',
      finalcolor:'#FFFFFF',
      tl : gsap.timeline()
    };
  },
  methods: {
    drawer(){
      //console.log(this.$route.path)
      //clearInterval(this.$store.state.clock)
      //this.$store.state.drawer=true
      //MorphSVGPlugin.convertToPath('rect')
      //var tl = gsap.timeline({paused: true});
      this.tl.to(".br0", {duration: 0.2, morphSVG: ".line0"},);
      this.tl.to(".br1", {duration: 0.2, morphSVG: ".line1"},0);
      this.tl.to(".line0 ", {duration: 0.2, morphSVG: ".x0", attr:{fill:'#FFFFFF'}},0.2);
      this.tl.to(".line1 ", {duration: 0.2, morphSVG: ".x1", attr:{fill:'#FFFFFF'}},0.2);
      this.tl.to(".br0", {duration: 0.2, morphSVG: ".x0", attr:{fill:'#FFFFFF'}},0.2);
      this.tl.to(".br1", {duration: 0.2, morphSVG: ".x1", attr:{fill:'#FFFFFF'}},0.2);
      if(this.$store.state.drawer==false){
        this.$store.state.drawer=true
        this.tl.restart();
      }else{
        this.$store.state.drawer=false
        this.tl.reverse();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#xx{
  visibility:hidden;
}
</style>
