<template>
  <div>
    <el-drawer
      :visible.sync="this.$store.state.drawer"
      direction="btt"
      :modal-append-to-body="false"
      size="100%">
      <el-row type="flex" style="height:100%">
        <el-col :xs="0" :sm="17">
          <img src="../assets/menu.webp" style="float:right;height:100%;"></img>
        </el-col>
        <el-col :xs="24" :sm="7" style="background-color:rgb(18,49,43);">
          <el-row type="flex">
            <el-col :xs="0" :sm="24" style="margin-top:110px;">&nbsp;</el-col>
          </el-row>
          <el-row>
            <el-col :xs="0" :sm="24">
              <span v-for="(list,index) in lists">
                <router-link :to="'/'+list.name" class="page-link" style="text-decoration:none;" @click.native="close">
                  <div v-show="list.name=='home'" style="margin-top:30px;"></div>
                  <p class="menu" :style="'color:rgba(255,255,255,'+list.opacity+')'" @mouseover="hover('1',list.name,index)" @mouseout="hover('0.5',list.name,index)">{{list.label}}</p>
                </router-link>
              </span>
              <p class="menu">
                <img class="menuicon" :src="require('../assets/'+this.fbicon+'.svg')" @mouseover="fbChange('facebook')" @mouseout="fbChange('fb')" @click="openWindow('fb')"></img>
                <img class="menuicon" :src="require('../assets/'+this.igicon+'.svg')" @mouseover="igChange('instagram')" @mouseout="igChange('ig')" @click="openWindow('ig')"></img>
              </p>
              <div style="font-size:20px;height:100px;text-align: center;">
                <span id="Japanese" style="cursor:pointer" :style="'color:rgba(255,255,255,'+Japaneseopacity+')'" @mouseover="Japaneseopacity='1'" @mouseout="Japaneseopacity='0.5'" @click="lang('Japanese')">日本語</span>
                /
                <span id="English" style="cursor:pointer" :style="'color:rgba(255,255,255,'+Englishopacity+')'" @mouseover="Englishopacity='1'" @mouseout="Englishopacity='0.5'" @click="lang('English')">English</span>
              </div>
            </el-col>
            <el-col :xs="24" :sm="0">
              <span v-for="(list,index) in lists">
                <router-link :to="'/'+list.name" class="page-link" style="text-decoration:none;" @click.native="close">
                  <div v-show="list.name=='home'" style="margin-top:30px;"></div>
                  <p class="exmenu" :style="'color:rgba(255,255,255,'+list.opacity+')'" @mouseover="hover('1',list.name,index)" @mouseout="hover('0.5',list.name,index)">{{list.label}}</p>
                </router-link>
              </span>
              <p class="menu">
                <img class="menuicon" :src="require('../assets/'+this.fbicon+'.svg')" @mouseover="fbChange('facebook')" @mouseout="fbChange('fb')" @click="openWindow('fb')"></img>
                <img class="menuicon" :src="require('../assets/'+this.igicon+'.svg')" @mouseover="igChange('instagram')" @mouseout="igChange('ig')" @click="openWindow('ig')"></img>
              </p>
              <div style="font-size:20px;height:200px;text-align: center;">
                <span id="Japanese" style="cursor:pointer" :style="'color:rgba(255,255,255,'+Japaneseopacity+')'" @mouseover="Japaneseopacity='1'" @mouseout="Japaneseopacity='0.5'" @click="lang('Japanese')">日本語</span>
                /
                <span id="English" style="cursor:pointer" :style="'color:rgba(255,255,255,'+Englishopacity+')'" @mouseover="Englishopacity='1'" @mouseout="Englishopacity='0.5'" @click="lang('English')">English</span>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="0" :sm="7" style="margin-left:-58.334%;background-color:rgba(0,0,0,0.5);height:100%;text-align: center;cursor: pointer;">
          <img id="logostyle" src="../assets/logo.svg" @click="home">
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      Japaneseopacity:'0.5',
      Englishopacity:'0.5',
      fbicon:'fb',
      igicon:'ig',
      aboutOpacity:'0.5',
      historyOpacity:'0.5',
      productOpacity:'0.5',
      designOpacity:'0.5',
      jtOpacity:'0.5',
      franchiseOpacity:'0.5',
      lists:[{
          name:'home',
          label:'Home',
          opacity:'0.5',
        },{
          name:'about',
          label:'About',
          opacity:'0.5',
        },{
          name:'history',
          label:'History',
          opacity:'0.5',
        },{
          name:'product',
          label:'Product',
          opacity:'0.5',
        },
        {
          name:'design',
          label:'Design',
          opacity:'0.5',
        },{
          name:'style',
          label:'TSUJIRI Lifestyle',
          opacity:'0.5',
        },{
          name:'shop',
          label:'Global Shops',
          opacity:'0.5',
        },{
          name:'franchise',
          label:'Franchise',
          opacity:'0.5',
        },
      ]
    };
  },
  watch: {
    router (x){
        console.log(x,'form watch')
    }
  },
  mounted() {
    clearInterval(this.$store.state.clock);
  },
  computed: {
    draw(){
      return this.$store.state.drawer
    },
    router(){
      if(this.$route.path=='/style'){
        this.$router.push('/style').catch(err => {err});
      }else{
        //location.reload()
      }
      return this.$route.path
    }
  },
  methods: {
    lang(lan) {
      this.$set(this.$i18n,'locale',lan)
      //this.$i18n.locale = lan;
      this.$store.state.drawer = false
      console.log(this.$i18n.locale,'????')
      //this.$set(this.$store.state,'lang','Japanese')
      //this.$store.state.lang = 'Japanese'
      /*if(lan === this.$i18n.locale){
        console.log('do nothing')
      }else{
        this.$i18n.locale = lan
      }*/
    },
    home(){
      var url = '/home'
      this.$router.push(url)
      this.$store.state.drawer=false
    },
    close(){
      //console.log(this.$route.path)
      this.$store.state.drawer=false

      if(this.$route.path!='/style'){
        //console.log('go not style')
        clearInterval(this.$store.state.clock);
      }else{
        //console.log('style')
        //this.$store.state.clock= setInterval(this.frame, 10,this.$store.state);
      }
    },
    openWindow(kind){
      if(kind=='fb'){
        window.open('https://www.facebook.com/tsujiri.tw/')
      }else{
        window.open('https://www.instagram.com/tsujiri_tw/')
      }
    },
    fbChange(newIcon){
      this.fbicon = newIcon
    },
    igChange(newIcon){
      this.igicon = newIcon
    },
    hover(color,obj,index){
      this.lists[index].opacity = color;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style media="screen">
#el-drawer__title{
  height:0px;
  visibility: hidden;
  padding: 0px;
  margin: 0px;
}
.page-link, .page-link:visited, .page-link:hover, .page-link:active, .page-link:focus {
  outline: 0 none !important;
  border: 0;
}
</style>
<style scoped>
#logostyle{
  margin-top:200px;
}
.menuicon{
  margin:8px;
  cursor: pointer;
}
.menu{
  font-family: "Garamond Premr Pro";
  font-size: 30px;
  text-align: center;
  margin-bottom: 22px;
  margin-top: 0px;
}
.exmenu{
  font-family: "Garamond Premr Pro";
  font-size: 25px;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 0px;
}
</style>
